import { EMPTY_OBJECT } from 'xnetjs/constants';
import MenuGroupSideTree from '../../components/MenuGroupSideTree';
import { HOVERING, LEAVING } from '../hooks/useHoveredState$';

export const MenuGroupSideTreeNav = (props) => {
  const {
    XeAppMenuNode = EMPTY_OBJECT,
    dispatchHovered,
    activePathXeAppMenuNodes,
  } = props;
  const { MenuNodeID } = XeAppMenuNode;

  return (
    <div
      data-component-name="MenuGroupSideTreeNav"
      className="menu-group-side-tree menu-group-side-tree--absolute"
      onMouseEnter={() =>
        dispatchHovered({ type: HOVERING, value: MenuNodeID })
      }
      onMouseLeave={() => dispatchHovered({ type: LEAVING })}
    >
      <MenuGroupSideTree
        XeAppMenuNode={XeAppMenuNode}
        activePathXeAppMenuNodes={activePathXeAppMenuNodes}
        hideGroupName
      />
    </div>
  );
};

export default MenuGroupSideTreeNav;
