export const labels = {
  USERNAME: 'Username',
  PASSWORD: 'Password',
  ENTER_SERVER_URL: 'Enter your server URL',
  EXAMPLE_URL: 'http://my.server.com',
  EXAMPLE: 'Example',
  LOG_IN: 'Log in',
  SUBMIT: 'Submit',
};

export const defaultDevServers = [
  { name: 'SiteThrasys1', url: 'https://devthr1.thrasys.com/Synergy' },
  { name: 'SiteThrasys2', url: 'https://devthr2.thrasys.com/Synergy' },
  { name: 'AC3', url: 'https://devac31.thrasys.com/Synergy' },
  { name: 'LACare', url: 'https://rx-app-qa-4.rxcurve360.com:8443/Synergy' },
  { name: 'Empirx', url: 'https://rx-app-qa-4.rxcurve360.com:8443/Synergy' },
  { name: 'Localhost', url: 'http://localhost:8080/Synergy' },
];
