import { useMemo, memo } from 'react';
import { DropDownList, Label } from 'xnetjs/components';
import { formatStaffName } from 'xnetjs/utils';
import { useXeLabels } from 'xnetjs/contexts/XeLabelContext';
import { MenuNodeLeafFeature } from 'xnetjs/contexts/XeMenuNodeContext/components';
import { usePathNavigate } from 'xnetjs/hooks/usePathNavigate';
import { useEnterprise } from 'xnetjs/contexts/XeEnterpriseContext';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'xnetjs/constants';
import { APP_FUNCTION_MENU } from 'xnetjs/contexts/XeMenuNodeContext/constants';
import { useRouteLocationDetails } from 'xnetjs/hooks/useRouteLocationDetails';

const AppFunctionsMenu = memo((props) => {
  const { menuNode } = props;

  if (!menuNode) return null;

  const { MenuNodeID } = menuNode;

  return <MenuNodeLeafFeature menuNodeId={MenuNodeID} {...props} />;
});

const toAppFunctionsMenuNode = (XeAppMenus = []) => {
  return XeAppMenus.find(({ MenuType }) => MenuType === APP_FUNCTION_MENU);
  //.reduce((acc, { XeAppMenus = [] }) => [...acc, ...XeAppMenus], []);
};

const labelFn = ({ EnterpriseName } = {}) => EnterpriseName;

export const EnterpriseBar = (props) => {
  const { XeAppMenus = EMPTY_ARRAY, featureMenuOpen } = props;

  const {
    userData: {
      XeEnterpriseData = EMPTY_ARRAY,
      XeResource = EMPTY_OBJECT,
      EnterpriseID,
    },
  } = useEnterprise();

  const [{ EnterpriseName = '' } = EMPTY_OBJECT] = XeEnterpriseData;
  const [selectedFeature = EMPTY_OBJECT] = useRouteLocationDetails();
  const appFunctionsNode = toAppFunctionsMenuNode(XeAppMenus);

  const memoizedEnterprise = useMemo(() => {
    return XeEnterpriseData.find(
      ({ EnterpriseID: possibleEnterpriseID } = {}) =>
        possibleEnterpriseID === EnterpriseID
    );
  }, [XeEnterpriseData, EnterpriseID]);

  const labels = useXeLabels();
  const navigate = usePathNavigate();

  const onSelectEnterprise = useMemo(() => {
    return ({ EnterpriseID }) => {
      navigate(`/${EnterpriseID}`);
    };
  }, [navigate]);

  return (
    <div data-component-name="EnterpriseBar" className="enterprise-bar">
      <div className="enterprise-bar__logo" />
      <div className="enterprise-bar-content">
        <div className="margin-left-small selected-feature-info">
          {!!selectedFeature && (
            <Label
              className={`username-label font-bold ${
                featureMenuOpen
                  ? 'xe-application__selected-feature-label--hidden'
                  : ''
              }`}
            >
              {selectedFeature.Name || ''}
            </Label>
          )}
        </div>
        <div className="enterprise-info-wrapper">
          <Label className="username-label">{`${labels.User}:`}</Label>
          <Label dataElementName="user" className="logged-in-user-label">
            {formatStaffName(XeResource)}
          </Label>
          <Label className="username-label">{`${labels.Enterprise}: `}</Label>
          {XeEnterpriseData.length > 1 ? (
            <DropDownList
              dataElementName="enterprises"
              data={XeEnterpriseData}
              labelFn={labelFn}
              onChange={onSelectEnterprise}
              value={memoizedEnterprise}
            />
          ) : (
            <Label
              dataElementName="enterpriseName"
              className="single-enterprise-label font-bold"
            >
              {EnterpriseName}
            </Label>
          )}
        </div>
        <AppFunctionsMenu menuNode={appFunctionsNode} />
      </div>
    </div>
  );
};

export default EnterpriseBar;
