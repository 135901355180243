import { castFunction } from 'xnetjs/fp/fp';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { Button, Icon, ToolTip } from 'xnetjs/components';
import { XeIconMap, MENU, WARNING_DUE } from 'xnetjs/icons';
import withClassNameModifiers from 'xnetjs/utils/withClassNameModifiers';
import { isSelectableFeature } from 'xnetjs/contexts/XeMenuNodeContext/components';
import { EMPTY_ARRAY, NOOP_FUNCTION } from 'xnetjs/constants';
import { usePathNavigate } from 'xnetjs/hooks/usePathNavigate';
import {
  APP_FUNCTION_MENU,
  MENUGROUPSIDETREE,
  MENU_DATUM,
} from 'xnetjs/contexts/XeMenuNodeContext/constants';
import { HOVERING, LEAVING } from '../hooks/useHoveredState$';

const isNavigableType = ({ Active, MenuType }) =>
  Active && MenuType !== APP_FUNCTION_MENU && MenuType !== MENU_DATUM;

const RoutingItem = (props) => {
  const { item, featureMenuOpen, isInCurrentPath = false, ...divProps } = props;

  const { MenuNodeID, Icon: menuIcon, Name } = item;

  const xeMenuIcon = XeIconMap[menuIcon] || WARNING_DUE;

  return (
    <label {...divProps} className="xe-application__menu-button-item">
      <Button
        key={MenuNodeID}
        className={withClassNameModifiers('xe-application__menu-button', {
          active: isInCurrentPath,
        })}
        dataElementName={Name}
      >
        <ToolTip disabled={featureMenuOpen} placement="right" value={Name}>
          <Icon
            icon={xeMenuIcon}
            className="xe-application__menu-button-icon"
          />
        </ToolTip>
        <span className="xe-application__menu-label">{Name}</span>
      </Button>
    </label>
  );
};

const toSetWidth = (setWidth) => (ref) => {
  if (!ref) {
    return;
  }
  return setWidth(ref.getBoundingClientRect().width);
};

export const NavigationBar = (props) => {
  const {
    featureMenuOpen = false,
    XeAppMenus = EMPTY_ARRAY,
    dispatchHovered = NOOP_FUNCTION,
    setNavBarWidth = NOOP_FUNCTION,
    selectedXeAppMenuNode,
    onToggleFeatureMenu,
  } = props;

  const navigationBarRef = useRef();
  const navigate = usePathNavigate();

  return (
    <div
      ref={(ref) => {
        if (ref) {
          toSetWidth(setNavBarWidth)(ref);
        }
        navigationBarRef.current = ref;
      }}
      className={withClassNameModifiers('xe-application-navigation-bar', {
        expanded: featureMenuOpen,
      })}
      style={{
        width: featureMenuOpen ? 'auto' : 60,
      }}
      data-component-name="NavigationBar"
    >
      <Button
        className="xe-application__menu-button-item xe-application__menu-toggle"
        onClick={() => castFunction(onToggleFeatureMenu)(!featureMenuOpen)}
        dataElementName="menuToggleButton"
      >
        <Icon icon={MENU} className="xe-application__menu-button-icon" />
      </Button>
      {XeAppMenus.filter(isNavigableType).map((XeAppMenuNode) => {
        const { MenuNodeID, MenuType } = XeAppMenuNode;
        const isSideTree = MENUGROUPSIDETREE === MenuType;
        const onMenuGroupSideTreeHovered = isSideTree
          ? (value) => dispatchHovered(value)
          : () => NOOP_FUNCTION;

        return (
          <RoutingItem
            featureMenuOpen={featureMenuOpen}
            isInCurrentPath={
              selectedXeAppMenuNode?.MenuNodeID === XeAppMenuNode?.MenuNodeID
            }
            item={XeAppMenuNode}
            key={MenuNodeID}
            onMouseEnter={() =>
              onMenuGroupSideTreeHovered({ type: HOVERING, value: MenuNodeID })
            }
            onMouseLeave={() => onMenuGroupSideTreeHovered({ type: LEAVING })}
            onClick={() => {
              if (isSelectableFeature(XeAppMenuNode)) {
                navigate(XeAppMenuNode);
              }
            }}
          />
        );
      })}
    </div>
  );
};

NavigationBar.propTypes = {
  featureMenuOpen: PropTypes.bool,
  XeAppMenus: PropTypes.array,
  dispatchHovered: PropTypes.func,
  setNavBarWidth: PropTypes.func,
};

export default NavigationBar;
