import { EMPTY_ARRAY, EMPTY_OBJECT } from 'xnetjs/constants';
import { MenuNodeLeafFeature } from 'xnetjs/contexts/XeMenuNodeContext/components';

export const XeAppFunctions = (props) => {
  const { menuNode: { XeAppMenus = EMPTY_ARRAY } = EMPTY_OBJECT, ...rest } =
    props;

  return (
    <div className="enterprise-bar__user-actions">
      {XeAppMenus.map((XeMenuNode) => {
        const { MenuNodeID } = XeMenuNode;

        return (
          <MenuNodeLeafFeature
            key={`function${MenuNodeID}`}
            menuNodeId={MenuNodeID}
            {...XeMenuNode}
            {...rest}
          />
        );
      })}
    </div>
  );
};

export default XeAppFunctions;
