import { combinePredicatedReducers } from 'xnetjs/connection/toConnectionDef';

import { and } from 'xnetjs/fp/pred';
import {
  localeFormat,
  luxonToDisplayString,
} from 'xnetjs/format/luxonToDisplayString';
import { DateTime } from 'luxon';
import { Flexbox, TextInput } from 'xnetjs/components';
import urlRegex from 'url-regex-safe';

import '../../css/features.css';
import '../styles.css';

import {
  UPDATE_USERNAME,
  UPDATE_PASSWORD,
  UPDATE_URL,
  SELECT_DEFAULT_SERVER,
} from '../actions';
import { ButtonBar, Button } from 'xnetjs/components';

import { labels, defaultDevServers } from '../constants';
import { useReducer } from 'react';

import reducers from '../reducers';
import { EMPTY_OBJECT } from 'xnetjs/constants';
import { toRequestInvoker } from 'xnetjs/service/toRequestFns';
import { toXNetLogin } from 'xnetjs/enterpriseContainer/XeDevelopmentAuthenticator/xnetLogin';
import toXnetAuthClient from 'xnetjs/auth/xnetAuthClient';
const reducer = combinePredicatedReducers(...reducers);

const lastCharNotEqualTo = (lastChar) => (string) =>
  string[string.length - 1] !== lastChar;
const isLastCharNotSlash = lastCharNotEqualTo('/');

const urlRegexExact = (exact) => (url) => urlRegex({ exact }).test(url);
const urlRegexExactTrue = urlRegexExact(true);

const isUrlValid = and(urlRegexExactTrue, isLastCharNotSlash);

const toAppVersion = (version, buildDate) =>
  `${version} - ${luxonToDisplayString(
    DateTime.fromISO(buildDate),
    localeFormat.LONG
  )}`;

const infoWithLabels = ({
  VERSION,
  BUILD_DATE,
  APP_HASH,
  SERVICES_HASH,
  XNET_HASH,
  FEATURES_HASH,
}) => [
  { label: 'App Version', data: toAppVersion(VERSION, BUILD_DATE) },
  { label: 'App SC Version', data: APP_HASH },
  { label: 'SVC SC Version', data: SERVICES_HASH },
  { label: 'XNET SC Version', data: XNET_HASH },
  { label: 'Features SC Version', data: FEATURES_HASH },
];

const toDynamicConfigCallbacks = (props, configValues) => {
  const { universe, onAuthClient, onRequestInvoker, requestConfigFn } = props;

  const { pathRoot, username, password, xeAcceptTerms = true } = configValues;

  const {
    streams: { toServiceErrors$ },
  } = universe;

  const updatedInvoker = toRequestInvoker({
    serviceErrors$: toServiceErrors$(),
    pathRoot: pathRoot,
  });

  toXNetLogin(
    updatedInvoker,
    requestConfigFn,
    username,
    password,
    xeAcceptTerms
  ).then((authorization) => {
    onRequestInvoker(updatedInvoker);
    onAuthClient(toXnetAuthClient(username, authorization, false));
  });
};

export const DefaultView = (props) => {
  const { universe } = props;

  const { info: aboutData = EMPTY_OBJECT } = universe;

  const [state, dispatch] = useReducer(reducer, EMPTY_OBJECT);

  const { pathRoot, username, password } = state;

  const isServerUrlValid = isUrlValid(pathRoot);
  const selectedUrlIndex = defaultDevServers.findIndex(
    (x) => x.url === pathRoot
  );

  return (
    <Flexbox
      className="bg-white fill-parent "
      alignItems="center"
      direction="column"
    >
      <Flexbox
        className="server-url-label margin-all-medium"
        direction="column"
        alignItems="center"
      >
        <span className="font-large"> BUILD SERVER LOGIN </span>
        <span> {document.title} </span>
      </Flexbox>
      <ButtonBar
        data={defaultDevServers}
        onSelect={(value) => dispatch({ type: SELECT_DEFAULT_SERVER, value })}
        labelFn={({ name }) => name}
        keyFn={({ url } = {}) => url}
        selectedItem={defaultDevServers[selectedUrlIndex]}
      />
      <TextInput
        type="url"
        className="server-url"
        placeholder={`${labels.ENTER_SERVER_URL}: ${labels.EXAMPLE_URL}`}
        onChange={(value) => dispatch({ type: UPDATE_URL, value })}
        value={pathRoot}
        autocapitalize="off"
        autocorrect="off"
      />
      <div className="separator">-----</div>
      <div className="login-form vertical-flex-container">
        <div className="login-fields">
          <TextInput
            type="text"
            className="username"
            placeholder={labels.Username}
            onChange={(value) => dispatch({ type: UPDATE_USERNAME, value })}
            onEnter={() => toDynamicConfigCallbacks(props, state)}
            value={username}
            autocapitalize="off"
            autocorrect="off"
            disabled={!isServerUrlValid}
          />
        </div>
        <div className="password-container flex-container">
          <TextInput
            type="password"
            className="password"
            placeholder={labels.Password}
            onChange={(value) => dispatch({ type: UPDATE_PASSWORD, value })}
            onEnter={() => toDynamicConfigCallbacks(props, state)}
            value={password}
            disabled={!isServerUrlValid}
          />
        </div>
      </div>
      <div className="submit-btn-wrapper">
        <Button
          onClick={() => toDynamicConfigCallbacks(props, state)}
          disabled={!isServerUrlValid || !(username && password)}
        >
          {labels.LOG_IN}
        </Button>
      </div>
      <div className="copyright" />
      <div style={{ border: 'solid 1px black' }}>
        {infoWithLabels(aboutData).map(({ data, label }, index) => (
          <div key={index}>
            <span>{label} </span>
            <span className="bold">{data}</span>
          </div>
        ))}
      </div>
    </Flexbox>
  );
};

export default DefaultView;
