import PropTypes from 'prop-types';
import { useState } from 'react';
import { Icon } from 'xnetjs/components';
import withClassNameModifiers from 'xnetjs/utils/withClassNameModifiers';
import { CHEVRON_RIGHT, CHEVRON_DOWN } from 'xnetjs/icons';
import '../../css/components.css';
import '../../css/features.css';
import './style.css';
import { usePathNavigate } from 'xnetjs/hooks/usePathNavigate';
import { EMPTY_ARRAY } from 'xnetjs/constants';

const MENU_GROUP_SIDE_TREE = 'MENUGROUPSIDETREE';

export const MenuGroupSideTree = (props) => {
  const {
    XeAppMenuNode,
    activePathXeAppMenuNodes = EMPTY_ARRAY,
    hideGroupName = false,
  } = props;

  const navigate = usePathNavigate();
  //navigate(XeAppMenuNode);

  const {
    Name = '',
    MenuType = '',
    MenuNodeID,
    XeAppMenus = EMPTY_ARRAY,
  } = XeAppMenuNode;

  const [isExpanded, setExpanded] = useState(true);
  const selected = activePathXeAppMenuNodes.some(
    ({ MenuNodeID: ActiveMenuNodeID }) => ActiveMenuNodeID === MenuNodeID
  );

  if (MenuType !== MENU_GROUP_SIDE_TREE) {
    return (
      <div
        data-element-name="menuGroupSideTreeNodeLeaf"
        onClick={() => navigate(XeAppMenuNode)}
        className={`menu-group-side-tree-node__leaf ${
          selected ? 'menu-group-side-tree-node__leaf--selected' : ''
        } flex-container flex-0 align-items-center`}
      >
        {Name}
      </div>
    );
  }

  return (
    <div
      data-element-name={Name}
      className="menu-group-side-tree-node vertical-flex-container flex-0"
    >
      {!hideGroupName && (
        <div
          onClick={() => setExpanded(!isExpanded)}
          className="menu-group-side-tree-node__name flex-container flex-0 align-items-center bold"
        >
          {isExpanded ? (
            <Icon
              dataElementName="menuGroupSideTreeCollapse"
              icon={CHEVRON_DOWN}
              className="menu-group-side-tree__icon--white"
            />
          ) : (
            <Icon
              dataElementName="menuGroupSideTreeExpand"
              icon={CHEVRON_RIGHT}
              className="menu-group-side-tree__icon--white"
            />
          )}
          {Name}
        </div>
      )}
      <div
        className={withClassNameModifiers('menu-group-side-tree__section', {
          collapsed: !isExpanded,
        })}
      >
        {XeAppMenus.map((XeAppMenuNode) => {
          const { MenuNodeID } = XeAppMenuNode;
          return (
            <MenuGroupSideTree
              XeAppMenuNode={XeAppMenuNode}
              activePathXeAppMenuNodes={activePathXeAppMenuNodes}
              key={MenuNodeID}
            />
          );
        })}
      </div>
    </div>
  );
};

MenuGroupSideTree.propTypes = {
  Name: PropTypes.string,
  MenuType: PropTypes.string,
};

export default MenuGroupSideTree;
