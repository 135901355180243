import { when } from 'xnetjs/predication/when';
import { isType } from 'xnetjs/connection/toConnectedComponent';
import { toActionValueReducer } from 'xnetjs/reducers';
import {
  UPDATE_USERNAME,
  UPDATE_PASSWORD,
  UPDATE_URL,
  SELECT_DEFAULT_SERVER,
} from './actions';

export default [
  when(isType(UPDATE_USERNAME))(toActionValueReducer('username')),
  when(isType(UPDATE_URL))(toActionValueReducer('pathRoot')),
  when(isType(UPDATE_PASSWORD))(toActionValueReducer('password')),
  when(isType(SELECT_DEFAULT_SERVER))(
    toActionValueReducer('pathRoot', ({ url }) => url)
  ),
];
