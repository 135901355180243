import { lazy } from 'react';

// const XeApplication = () =>
//   import(/* webpackChunkName: "XeApplication" */ './XeApplication');
// export { XeApplication };

export { XeApplication } from './XeApplication';

const XeAttachments = lazy(() =>
  import(/* webpackChunkName: "XeAttachments" */ './XeAttachments')
);
export { XeAttachments };

const XeAttachmentsFax = lazy(() =>
  import(/* webpackChunkName: "XeAttachmentsFax" */ './XeAttachmentsFax')
);
export { XeAttachmentsFax };

const XeAttachmentsPatient = lazy(() =>
  import(
    /* webpackChunkName: "XeAttachmentsPatient" */ './XeAttachmentsPatient'
  )
);
export { XeAttachmentsPatient };

const XeNotifications = lazy(() =>
  import(/* webpackChunkName: "XeNotifications" */ './XeNotifications')
);
export { XeNotifications };

const XeNotificationsConfiguration = lazy(() =>
  import(
    /* webpackChunkName: "XeNotificationsConfiguration" */ './XeNotificationsConfiguration'
  )
);
export { XeNotificationsConfiguration };

const XeUserNotifications = lazy(() =>
  import(/* webpackChunkName: "XeUserNotifications" */ './XeUserNotifications')
);
export { XeUserNotifications };

const XePatientCollection = lazy(() =>
  import(/* webpackChunkName: "XePatientCollection" */ './XePatientCollection')
);
export { XePatientCollection };

const XePatient = lazy(() =>
  import(/* webpackChunkName: "XePatient" */ './XePatient')
);
export { XePatient };

const XePatientSide = lazy(() =>
  import(/* webpackChunkName: "XePatientSide" */ './XePatientSide')
);
export { XePatientSide };

const XePatientVisits = lazy(() =>
  import(/* webpackChunkName: "XePatientVisits" */ './XePatientVisits')
);
export { XePatientVisits };

const XePatientRisk = lazy(() =>
  import(/* webpackChunkName: "XePatientRisk" */ './XePatientRisk')
);
export { XePatientRisk };

const XePatientClaims = lazy(() =>
  import(/* webpackChunkName: "XePatientClaims" */ './XePatientClaims')
);
export { XePatientClaims };

const XeFiles = lazy(() =>
  import(/* webpackChunkName: "XeFiles" */ './XeFiles')
);
export { XeFiles };

const XeHousingSummaryPanel = lazy(() =>
  import(
    /* webpackChunkName: "XeHousingSummaryPanel" */ './XeHousingSummaryPanel'
  )
);
export { XeHousingSummaryPanel };

const XeHousingSummary = lazy(() =>
  import(/* webpackChunkName: "XeHousingSummary" */ './XeHousingSummary')
);
export { XeHousingSummary };

const XePatientSearch = lazy(() =>
  import(
    /* webpackChunkName: "XePatientSearch" */ 'xnetjs/widgets/XePatientSearchWidget/picker'
  )
);
export { XePatientSearch };

const XeAbout = lazy(() =>
  import(/* webpackChunkName: "XeAbout" */ './XeAbout')
);
export { XeAbout };

const XeHelp = lazy(() => import(/* webpackChunkName: "XeHelp" */ './XeHelp'));
export { XeHelp };

const XePatientLists = lazy(() =>
  import(/* webpackChunkName: "XePatientLists" */ './XePatientLists')
);
export { XePatientLists };

const XeEditPatientList = lazy(() =>
  import(/* webpackChunkName: "XeEditPatientList" */ './XeEditPatientList')
);
export { XeEditPatientList };

const XeLogout = lazy(() =>
  import(/* webpackChunkName: "XeLogout" */ './XeLogout')
);
export { XeLogout };

const XePatientContacts = lazy(() =>
  import(/* webpackChunkName: "XePatientContacts" */ './XePatientContacts')
);
export { XePatientContacts };

const XePatientProviders = lazy(() =>
  import(/* webpackChunkName: "XePatientProviders" */ './XePatientProviders')
);
export { XePatientProviders };

const XeBuildServerLogin = lazy(() =>
  import(/* webpackChunkName: "XeBuildServerLogin" */ './XeBuildServerLogin')
);
export { XeBuildServerLogin };

const XePatientQuerySearch = lazy(() =>
  import(
    /* webpackChunkName: "XePatientQuerySearch" */ './XePatientQuerySearch'
  )
);
export { XePatientQuerySearch };

const XeRegistryDemographics = lazy(() =>
  import(
    /* webpackChunkName: "XeRegistryDemographics" */ './XeRegistryDemographics'
  )
);
export { XeRegistryDemographics };

const XeDrugGenericSearch = lazy(() =>
  import(/* webpackChunkName: "XeDrugGenericSearch" */ './XeDrugGenericSearch')
);
export { XeDrugGenericSearch };

const XeVaccineSearch = lazy(() =>
  import(/* webpackChunkName: "XeVaccineSearch" */ './XeVaccineSearch')
);
export { XeVaccineSearch };

const XeAlertAgentSearch = lazy(() =>
  import(/* webpackChunkName: "XeAlertAgentSearch" */ './XeAlertAgentSearch')
);
export { XeAlertAgentSearch };

const XeDiagnosisSearch = lazy(() =>
  import(/* webpackChunkName: "XeDiagnosisSearch" */ './XeDiagnosisSearch')
);
export { XeDiagnosisSearch };

const XeRiskConditionSearch = lazy(() =>
  import(
    /* webpackChunkName: "XeRiskConditionSearch" */ './XeRiskConditionSearch'
  )
);
export { XeRiskConditionSearch };

const XeStaffSearch = lazy(() =>
  import(/* webpackChunkName: "XeStaffSearch" */ './XeStaffSearch')
);
export { XeStaffSearch };

const XeStaffRoleSearch = lazy(() =>
  import(/* webpackChunkName: "XeStaffRoleSearch" */ './XeStaffRoleSearch')
);
export { XeStaffRoleSearch };

const XeObservationSearch = lazy(() =>
  import(/* webpackChunkName: "XeObservationSearch" */ './XeObservationSearch')
);
export { XeObservationSearch };

const XeLabTestSearch = lazy(() =>
  import(/* webpackChunkName: "XeLabTestSearch" */ './XeLabTestSearch')
);
export { XeLabTestSearch };

const XeVisitTypeSearch = lazy(() =>
  import(/* webpackChunkName: "XeVisitTypeSearch" */ './XeVisitTypeSearch')
);
export { XeVisitTypeSearch };

const XeAddPatient = lazy(() =>
  import(/* webpackChunkName: "XeAddPatient" */ './XeAddPatient')
);
export { XeAddPatient };

const XeAddPatientAttest = lazy(() =>
  import(/* webpackChunkName: "XeAddPatientAttest" */ './XeAddPatientAttest')
);
export { XeAddPatientAttest };

const XeAppointmentCalendar = lazy(() =>
  import(
    /* webpackChunkName: "xeappointmentcalendar" */ './XeAppointmentCalendar'
  )
);
export { XeAppointmentCalendar };

const XeAppointmentBrowserCase = lazy(() =>
  import(
    /* webpackChunkName: "XeAppointmentBrowserCase" */ './XeAppointmentBrowserCase'
  )
);
export { XeAppointmentBrowserCase };

const XeTestComponents = lazy(() =>
  import(/* webpackChunkName: "XeTestComponents" */ './XeTestComponents')
);
export { XeTestComponents };

const XeTestSchemaComponents = lazy(() =>
  import(
    /* webpackChunkName: "XeTestSchemaComponents" */ './XeTestSchemaComponents'
  )
);
export { XeTestSchemaComponents };

const XeCallLogBrowserPatient = lazy(() =>
  import(
    /* webpackChunkName: "XeCallLogBrowserPatient" */ './XeCallLogBrowserPatient'
  )
);
export { XeCallLogBrowserPatient };

const XeCallLogBrowser = lazy(() =>
  import(
    /* webpackChunkName: "XeCallLogBrowser" */ './XeCallLogBrowserPatient'
  ).then((module) => {
    //React lazy uses the default export of the module see: https://github.com/reactjs/rfcs/pull/64#issuecomment-431507924
    return {
      default: module.XeCallLogBrowser,
    };
  })
);
export { XeCallLogBrowser };

const XeQuadrantTabs = lazy(() =>
  import(/* webpackChunkName: "XeQuadrantTabs" */ './XeQuadrantTabs')
);
export { XeQuadrantTabs };

const XeNotificationsEvent = lazy(() =>
  import(
    /* webpackChunkName: "XeNotificationsEvent" */ './XeNotificationsEvent'
  )
);
export { XeNotificationsEvent };

const XeNotificationsInbox = lazy(() =>
  import(
    /* webpackChunkName: "XeNotificationsInbox" */ './XeNotificationsInbox'
  )
);
export { XeNotificationsInbox };

const NotificationsInbox = lazy(
  () => /* webpackChunkName: "NotificationsInbox" */ './NotificationsInbox'
);
export { NotificationsInbox };

const NotificationsOutbox = lazy(
  () => /* webpackChunkName: "NotificationsOutbox" */ './NotificationsOutbox'
);
export { NotificationsOutbox };

const NotificationsArchive = lazy(
  () => /* webpackChunkName: "NotificationsArchive" */ './NotificationsArchive'
);
export { NotificationsArchive };

const XePatientProblems = lazy(() =>
  import(/* webpackChunkName: "XePatientProblems" */ './XePatientProblems')
);
export { XePatientProblems };

const XeNotificationsThread = lazy(() =>
  import(
    /* webpackChunkName: "XeNotificationsThread" */ './XeNotificationsThread'
  )
);
export { XeNotificationsThread };

const XePowerBI = lazy(() =>
  import(/* webpackChunkName: "XePowerBI" */ './XePowerBI')
);
export { XePowerBI };

const XeUserSummary = lazy(() =>
  import(/* webpackChunkName: "XeUserSummary" */ './XeUserSummary')
);
export { XeUserSummary };

const XeAddUser = lazy(() =>
  import(/* webpackChunkName: "XeAddUser" */ './XeAddUser')
);
export { XeAddUser };

const XePatientMedications = lazy(() =>
  import(
    /* webpackChunkName: "XePatientMedications" */ './XePatientMedications'
  )
);
export { XePatientMedications };

const XePatientAlerts = lazy(() =>
  import(/* webpackChunkName: "XePatientAlerts" */ './XePatientAlerts')
);
export { XePatientAlerts };

const XePatientVaccines = lazy(() =>
  import(/* webpackChunkName: "XePatientVaccines" */ './XePatientVaccines')
);
export { XePatientVaccines };

const XePatientVisitDiags = lazy(() =>
  import(/* webpackChunkName: "XePatientVisitDiags" */ './XePatientVisitDiags')
);
export { XePatientVisitDiags };

const XePatientDocuments = lazy(() =>
  import(/* webpackChunkName: "XePatientDocuments" */ './XePatientDocuments')
);
export { XePatientDocuments };

const XePatientPrograms = lazy(() =>
  import(/* webpackChunkName: "XePatientPrograms" */ './XePatientPrograms')
);
export { XePatientPrograms };

const XePagedDashboard = lazy(() =>
  import(/* webpackChunkName: "XePagedDashboard" */ './XePagedDashboard')
);
export { XePagedDashboard };

const XeDashboardAdhoc = lazy(() =>
  import(/* webpackChunkName: "XeDashboardAdhoc" */ './XeDashboardAdhoc')
);
export { XeDashboardAdhoc };

const XePatientContactsBrowser = lazy(() =>
  import(
    /* webpackChunkName: "XePatientContactsBrowser" */ './XePatientContactsBrowser'
  )
);
export { XePatientContactsBrowser };

const XePatientDemographics = lazy(() =>
  import(
    /* webpackChunkName: "XePatientDemographics" */ './XePatientDemographics'
  )
);
export { XePatientDemographics };

const XePatientHealthPlans = lazy(() =>
  import(
    /* webpackChunkName: "XePatientHealthPlans" */ './XePatientHealthPlans'
  )
);
export { XePatientHealthPlans };

const XeEditPatientDemographics = lazy(() =>
  import(
    /* webpackChunkName: "XeEditPatientDemographics" */ './XeEditPatientDemographics'
  )
);
export { XeEditPatientDemographics };

const XePatientOrdersLabs = lazy(() =>
  import(/* webpackChunkName: "XePatientOrdersLabs" */ './XePatientOrdersLabs')
);
export { XePatientOrdersLabs };

const XePatientVitalsAll = lazy(() =>
  import(/* webpackChunkName: "XePatientVitalsAll" */ './XePatientVitalsAll')
);
export { XePatientVitalsAll };

const XeCaseProgramSearch = lazy(() =>
  import(/* webpackChunkName: "XeCaseProgramSearch" */ './XeCaseProgramSearch')
);
export { XeCaseProgramSearch };

const XeClindocBrowse = lazy(() =>
  import(/* webpackChunkName: "XeClindocBrowse" */ './XeClindocBrowse')
);
export { XeClindocBrowse };

const XeClaimBrowser = lazy(() =>
  import(/* webpackChunkName: "XeClaimBrowser" */ './XeClaimBrowser')
);
export { XeClaimBrowser };

const XePatientClaimBrowser = lazy(() =>
  import(/* webpackChunkName: "XeClaimBrowser" */ './XeClaimBrowser')
);
export { XePatientClaimBrowser };

/* Connected Components */
const AddCallLogPopup = lazy(() =>
  import(
    /* webpackChunkName: "AddCallLogPopup" */ 'xnetjs/components/AddCallLogPopup'
  )
);
export { AddCallLogPopup };

const PatientProblemDetails = lazy(() =>
  import(
    /* webpackChunkName: "PatientProblemDetails" */ './XePatientProblems/components/PatientProblemDetails'
  )
);
export { PatientProblemDetails };

const PatientAlertsDetails = lazy(() =>
  import(
    /* webpackChunkName: "PatientAlertsDetails" */ './XePatientAlerts/components/PatientAlertsDetails'
  )
);
export { PatientAlertsDetails };

const XeWorklistIssue = lazy(() =>
  import(/* webpackChunkName: "XeWorklistIssue" */ './XeWorklistIssue')
);
export { XeWorklistIssue };

const XeAuthIntake = lazy(() =>
  import(/* webpackChunkName: "XeAuthIntake" */ './XeAuthIntake')
);
export { XeAuthIntake };

const XeProviderAuthBrowser = lazy(() =>
  import(
    /* webpackChunkName: "XeProviderAuthBrowser" */ './XeProviderAuthBrowser'
  )
);
export { XeProviderAuthBrowser };

const XePatientAuthBrowser = lazy(() =>
  import(
    /* webpackChunkName: "XePatientAuthBrowser" */ './XePatientAuthBrowser'
  )
);
export { XePatientAuthBrowser };

const XeWorklistSummary = lazy(() =>
  import(/* webpackChunkName: "XeWorklistSummary" */ './XeWorklistSummary')
);
export { XeWorklistSummary };

const XeWorklistAuth = lazy(() =>
  import(/* webpackChunkName: "XeWorklistAuth" */ './XeWorklistAuth')
);
export { XeWorklistAuth };

const XeWorklist = lazy(() =>
  import(/* webpackChunkName: "XeWorklist" */ './XeWorklist')
);
export { XeWorklist };

const XeIssueBrowser = lazy(() =>
  import(/* webpackChunkName: "XeIssueBrowser" */ './XeIssueBrowser')
);
export { XeIssueBrowser };

const XePatientIssueBrowser = lazy(() =>
  import(/* webpackChunkName: "XePatientIssueBrowser" */ './XeIssueBrowser')
);
export { XePatientIssueBrowser };

const XeCorrespondenceBrowser = lazy(() =>
  import(
    /* webpackChunkName: "XeCorrespondenceBrowser" */ './XeCorrespondenceBrowser'
  )
);
export { XeCorrespondenceBrowser };

const XeSmartBookViewer = lazy(() =>
  import(/* webpackChunkName: "XeSmartBookViewer" */ './XeSmartBookViewer')
);
export { XeSmartBookViewer };

const XeDashboardPopup = lazy(() =>
  import(/* webpackChunkName: "XeDashboardPopup" */ './XeDashboardPopup')
);
export { XeDashboardPopup };

const XeConsentManagement = lazy(() =>
  import(/* webpackChunkName: "XeConsentManagement" */ './XeConsentManagement')
);
export { XeConsentManagement };

/* Patient Demographics */
const EditClinicalContacts = lazy(() =>
  import(
    /* webpackChunkName: "EditClinicalContacts" */ './components/EditClinicalContacts'
  )
);
export { EditClinicalContacts };

const EditPersonalContacts = lazy(() =>
  import(
    /* webpackChunkName: "EditPersonalContacts" */ './components/EditPersonalContacts'
  )
);
export { EditPersonalContacts };

const EditAddress = lazy(() =>
  import(/* webpackChunkName: "EditAddress" */ './components/EditAddress')
);
export { EditAddress };

const EditHealthPlans = lazy(() =>
  import(
    /* webpackChunkName: "EditHealthPlans" */ './components/EditHealthPlans'
  )
);
export { EditHealthPlans };

const EventNotificationConfig = lazy(() =>
  import(
    /* webpackChunkName: "EventNotificationConfig" */ './components/EventNotificationConfig'
  )
);
export { EventNotificationConfig };

const XePQRBrowser = lazy(() =>
  import(/* webpackChunkName: "XePQRBrowser" */ './XePQRBrowser')
);
export { XePQRBrowser };

const XeConfigCapacitySchedule = lazy(() =>
  import(
    /* webpackChunkName: "XeConfigCapacitySchedule" */ './XeConfigCapacitySchedule'
  )
);
export { XeConfigCapacitySchedule };

const XeCapacitySchedule = lazy(() =>
  import(/* webpackChunkName: "XeCapacitySchedule" */ './XeCapacitySchedule')
);
export { XeCapacitySchedule };

const XeGridTabs = lazy(() =>
  import(/* webpackChunkName: "XeGridTabs" */ './XeGridTabs')
);
export { XeGridTabs };

const XeFileJobs = lazy(() =>
  import(/* webpackChunkName: "XeFileJobs" */ './XeFileJobs')
);
export { XeFileJobs };

// const XeAppFunctions = lazy(() =>
//   import(/* webpackChunkName: "XeAppFunctions" */ './XeAppFunctions')
// );
// export { XeAppFunctions };
export { XeAppFunctions } from './XeAppFunctions';
