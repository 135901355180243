import { debounceTime, filter, mapTo } from 'rxjs';
import { useReducer$ } from 'xnetjs/hooks/useReducer$';

export const HOVERING = 'hovering';
export const LEAVING = 'leaving';
export const EXPIRED = 'expired';

const LAG_TIME = 300;

const reducer = (state = false, action) => {
  const { type, value } = action;

  if (type === HOVERING) {
    return value;
  } else if (type === EXPIRED) {
    return false;
  }

  return state;
};

const epic = (action$) => {
  return action$.pipe(
    debounceTime(LAG_TIME),
    filter(({ type }) => type === LEAVING),
    mapTo({ type: EXPIRED })
  );
};

export const useHoveredState$ = () => {
  const stateAndDispatch = useReducer$(reducer, epic);

  return stateAndDispatch;
};
