import { useEnterprise } from 'xnetjs/contexts/XeEnterpriseContext';
import { useXeLabels } from 'xnetjs/contexts/XeLabelContext';
import { Label, ToolTip, Flexbox, Icon } from 'xnetjs/components';
import { ANON_DB_NAME, EMPTY_ARRAY } from 'xnetjs/constants';
import { INFORMATION, SHIELD_UNLOCK, SHIELD_LOCK } from 'xnetjs/icons';
import { info } from '../../../../src/js/info';
import '../styles.css';

const DATABASE_CLASS = {
  Production: 'red',
  TruncatedProduction: 'yellow',
  Anonymized: 'green',
  'dbRefreshType-none': 'grey',
};

const DATABASE_TYPE = {
  Production: 'DbRefreshTypeProd',
  TruncatedProduction: 'DbRefreshTypeTrunc',
  Anonymized: 'DbRefreshTypeAnon',
  'dbRefreshType-none': 'DbRefreshTypeLocal',
};

const ENV_CLASS = {
  test: 'red',
  qa: 'yellow',
  dev: 'green',
  'env-none': 'grey',
  stg: 'warm',
};

const ENV_TYPE = {
  test: 'EnvTest',
  qa: 'EnvQA',
  dev: 'EnvDev',
  stg: 'EnvStaging',
  'env-none': 'EnvLocal',
};

const toDatabaseLabel = (type) => {
  return DATABASE_TYPE[type];
};

const toDatabaseClass = (type) => {
  return DATABASE_CLASS[type];
};

const toEnvClass = (type) => {
  return ENV_CLASS[type];
};

const toEnvLabel = (type) => {
  return ENV_TYPE[type];
};

export const InformationBar = () => {
  const {
    userData: { InProduction = true },
  } = useEnterprise();
  const labels = useXeLabels();
  const [body] = window.document.getElementsByTagName('body') || EMPTY_ARRAY;
  const {
    dataset: { dbrefreshtype, env },
  } = body;

  const isLocal = env === 'env-none';

  const hasPHI =
    !InProduction && (isLocal || (!isLocal && dbrefreshtype !== ANON_DB_NAME));

  return !InProduction ? (
    <div className="information-bar">
      <Flexbox justifyContent="space-between">
        <Flexbox
          direction="row"
          alignItems="center"
          className="padding-vertical-small"
        >
          <Icon icon={INFORMATION} />
          <Label className="margin-left-small margin-right-small">{`${labels.SyntranetCopyright} - ${info.VERSION}`}</Label>

          <div
            className={`information-bar-pill information-bar__database-${toDatabaseClass(
              dbrefreshtype
            )} margin-right-small`}
          >
            <Label className="information-bar__pill-label padding-vertical-small">
              {`${labels.Database}: ${labels[toDatabaseLabel(dbrefreshtype)]}`}
            </Label>
          </div>
          <ToolTip value={labels.TestSystemTooltip}>
            <div
              className={`information-bar-pill information-bar__env-${toEnvClass(
                env
              )} margin-right-small`}
            >
              <Label className="information-bar__pill-label padding-vertical-small">{`${
                labels.Environment
              }: ${labels[toEnvLabel(env)]}`}</Label>
            </div>
          </ToolTip>
        </Flexbox>
        <Flexbox direction="row" alignItems="center">
          <div
            className={`information-bar__phi-label padding-horizontal-large ${
              hasPHI
                ? 'information-bar__has-phi'
                : 'information-bar__has-no-phi'
            } margin-left-medium`}
          >
            <Icon
              icon={hasPHI ? SHIELD_LOCK : SHIELD_UNLOCK}
              className="information-bar__phi-label-text"
            />
            <Label className="information-bar__phi-label-text margin-left-small">
              {hasPHI ? labels.ContainsPHI : labels.NonPHI}
            </Label>
          </div>
        </Flexbox>
      </Flexbox>
    </div>
  ) : null;
};

export default InformationBar;
