import { useState } from 'react';
import '../../css/features.css';
import EnterpriseBar from '../components/EnterpriseBar';
import MenuGroupSideTreeNav from '../components/MenuGroupSideTreeNav';
import NavigationBar from '../components/NavigationBar';
import InformationBar from '../components/InformationBar';
import { Outlet } from 'react-router-dom';

import '../styles.css';
import { useMenuNode } from 'xnetjs/contexts/XeMenuNodeContext';
import { EMPTY_ARRAY, EMPTY_OBJECT } from 'xnetjs/constants';
import { useLocalStorage } from 'xnetjs/hooks/useLocalStorage';
import { MENUGROUPSIDETREE } from 'xnetjs/contexts/XeMenuNodeContext/constants';
import { useHoveredState$ } from '../hooks/useHoveredState$';
import { useRouteLocationDetails } from 'xnetjs/hooks/useRouteLocationDetails';

export const XeApplication = (props) => {
  const { identifierName = 'XeApplication', className } = props;

  const { XeAppMenuNode } = useMenuNode();
  const { XeAppMenus = EMPTY_ARRAY } = XeAppMenuNode;

  const [menuGroupSideTreeMenuNodeID, dispatchHovered] = useHoveredState$();
  //We need to keep track of the navBarWidth because the MenuGroupSideTree if hovered is positioned absolute
  const [navBarWidth, setNavBarWidth] = useState(0);
  const activePathXeAppMenuNodes = useRouteLocationDetails();
  const [selectedXeAppMenuNode = EMPTY_OBJECT] = activePathXeAppMenuNodes;

  const [featureMenuOpen, setFeatureMenuOpen] = useLocalStorage(
    'featureMenuOpen',
    true
  );

  const baseClassName = 'xe-application vertical-flex-container display-grid';
  const SideTreeXeAppMenuNode = XeAppMenus.find(
    ({ MenuType, MenuNodeID }) =>
      MenuType === MENUGROUPSIDETREE &&
      MenuNodeID === menuGroupSideTreeMenuNodeID
  );

  return (
    <div
      className={className ? `${baseClassName} ${className}` : baseClassName}
      data-xe-feature-name={identifierName}
    >
      <EnterpriseBar
        featureMenuOpen={featureMenuOpen}
        XeAppMenus={XeAppMenus}
      />
      {/* main content */}
      <div className="xe-application__frame display-grid">
        <div className="xe-application__navigation-bar-combo">
          <NavigationBar
            featureMenuOpen={featureMenuOpen}
            XeAppMenus={XeAppMenus}
            selectedXeAppMenuNode={selectedXeAppMenuNode}
            dispatchHovered={dispatchHovered}
            setNavBarWidth={setNavBarWidth}
            onToggleFeatureMenu={(value) => {
              setFeatureMenuOpen(value);
            }}
          />
          {SideTreeXeAppMenuNode && (
            <MenuGroupSideTreeNav
              XeAppMenuNode={SideTreeXeAppMenuNode}
              activePathXeAppMenuNodes={activePathXeAppMenuNodes}
              dispatchHovered={dispatchHovered}
              navBarWidth={navBarWidth}
            />
          )}
        </div>
        <div className="feature-container">
          <Outlet />
          <InformationBar />
        </div>
      </div>
    </div>
  );
};

export default XeApplication;
